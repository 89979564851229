<template>
  <v-data-table
    :headers="transactions_headers"
    :items="mother_transactions"
    :items-per-page="10"
    :loading="loading"
    loading-text="Loading... Please wait"
    class="elevation-0"
  >
    <template v-slot:item.active_status="{ item }">
      <v-chip
        :color="
          item.status == 'Pending'
            ? 'warning'
            : item.status == 'Approved'
            ? 'green'
            : 'red'
        "
        dark
      >
        {{ item.status }}
      </v-chip>
    </template>
    <template v-slot:item.action="{ item }">
      <p
        class="mb-0 blue--text text-decoration-underline"
        style="cursor: pointer"
        @click="onViewReceipt(item)"
      >
        View Receipt
      </p>
    </template>
    <template v-slot:item.custom_date="{ item }">
      <p v-if="item.created_date">
        {{ new Date(item.created_date).toLocaleString() }}
      </p>
    </template>
    <template v-slot:item.approve_action="{ item }">
      <v-btn text @click="onApprove(item)" v-if="item.status == 'Pending'">
        Approve
      </v-btn>
      <v-btn text @click="onApprove(item)" v-else> Details </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      transactions_headers: [
        {
          text: "Type",
          value: "deposit_type",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Reference No",
          align: "start",
          sortable: false,
          value: "reference_number",
          class: "custom-header black--text",
          divider: true,
        },
        {
          text: "Status",
          value: "active_status",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },
        {
          text: "Created Date",
          value: "custom_date",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Receipt",
          value: "action",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },

        // {
        //   text: "Currency",
        //   value: "iron",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        // },
        {
          text: "Amount",
          value: "amount",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        // {
        //   text: "Deposited Currency",
        //   value: "iron",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        // },
        {
          text: "Deposited Amount",
          value: "deposit_amount",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Action",
          value: "approve_action",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },
      ],
      mother_transactions: [],
    };
  },

  methods: {
    // Get all transaction list
    getAllTransactions() {
      this.loading = true;
      this.mother_transactions = [];
      axios
        .get(`impress/show_received_transactions/`)
        .then((mother_transactions_response) => {
          if (
            mother_transactions_response.data.success &&
            mother_transactions_response.data.data
          ) {
            this.mother_transactions = mother_transactions_response.data.data;
          } else {
            this.mother_transactions = [];
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.mother_transactions = [];
        });
    },

    // View receipt
    onViewReceipt(item) {
      // console.log({ item });
    },

    onApprove(item) {
      this.$router.push(
        `/dashboard/pages/payment/approve-transaction?TID=${item.id}`
      );
    },
  },

  created() {
    this.getAllTransactions();
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>