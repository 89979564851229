var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.transactions_headers,"items":_vm.mother_transactions,"items-per-page":10,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.active_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status == 'Pending'
          ? 'warning'
          : item.status == 'Approved'
          ? 'green'
          : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"mb-0 blue--text text-decoration-underline",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onViewReceipt(item)}}},[_vm._v(" View Receipt ")])]}},{key:"item.custom_date",fn:function(ref){
          var item = ref.item;
return [(item.created_date)?_c('p',[_vm._v(" "+_vm._s(new Date(item.created_date).toLocaleString())+" ")]):_vm._e()]}},{key:"item.approve_action",fn:function(ref){
          var item = ref.item;
return [(item.status == 'Pending')?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onApprove(item)}}},[_vm._v(" Approve ")]):_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onApprove(item)}}},[_vm._v(" Details ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }